import { RegistrationFormUser } from "../components/RegistrationFormUser";

function RegistroAprendiz (){
    return (
        <div className="bg-gray-200">
            <RegistrationFormUser/>
        </div>
    )
}

export {RegistroAprendiz};